import { ImpactFigure } from "../../../../components/impact-figure/impact-figure"
import { Quote } from '../../../../components/quote/quote'

import CSS from './in-numbers.module.css'

export const InNumbers = () => {

    const sectionHeading = `fs-1 c-secondary lh-1 caps`
    
    return (
        <section className={CSS.layout}>
            {/* <div className={CSS.graphic}>
                
            </div> */}
            
            <div className={CSS.quote}>
                <Quote 
                    avatar={{ url: `/assets/img/homepage/president-mwinyi.jpg`, alt: `Dr. Hussein Ali Mwinyi`}}
                    quote={`Zanzibar has bold development and investment ambitions, through AviaDev 2025, we can enhance flight connectivity, unlock new opportunities for growth and foster collaboration globally. The Government of Zanzibar through Zanzibar Commission for Tourism and Zanzibar Airports Authority is looking forward to host this forum for delegates from different regions to come together and share experiences.`} 
                    name={`Dr. Hussein Ali Mwinyi`} 
                    title={`The President of Zanzibar and Chairman of the Revolutionary Council`}
                    decal={`c-primary fs-3 fw-500`}
                />
                <div className="mt-md">
                    <Quote 
                        avatar={{ url: `https://cms.thebench.com/assets/img/avatars/Girma-Wake.jpg`, alt: `Girma Wake`}}
                        quote={`It was an excellent and productive meeting. In two days we achieved what we normally don't achieve in a week`} 
                        name={`Ato Girma Wake`} 
                        title={`Industry Expert`}
                        decal={`c-primary fs-3 caps`}
                    />
                </div>
            </div>
            
            <div className={CSS.impact}>
                <p className={sectionHeading}>AviaDev in numbers</p>
                <div className={CSS.impactGrid}>
                    <ImpactFigure figure={`500+`} caption={`Delegates`} />
                    <ImpactFigure figure={`36`} caption={`Airlines`} />
                    <ImpactFigure figure={`75%`} caption={`Attendees from Africa`} />
                    <ImpactFigure figure={`1200+`} caption={`Pre-arranged meetings delivered`} />
                    <ImpactFigure figure={`46`} caption={`Countries represented`} />
                </div>
            </div>
        </section>
    )
}